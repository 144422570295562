<template>
	<reaview-component />
</template>

<script>
import Vue from "vue";
import VueCalendly from 'vue-calendly';
import ReaviewComponent from "../../components/org-owner/compliance/Review.vue";
Vue.use(VueCalendly)

export default {
  components: {
    ReaviewComponent,
  },
}
</script>

<style></style>
